<template>
  <div>
    <Icon />
    <Nav />
    <div class="grid-box">
      <v-layout row px-4 pt-4 pb-0>
        <div class="my-panel w-100">
          <div class="photo-user-panel">
            <img :src="getUrl()" alt />
          </div>
          <div class="info-user-panel pl-4">
            <h2 v-if="person.socialReason == null">{{ person.name }}</h2>
            <h2 v-else>{{ person.socialReason }}</h2>
            <div>
              <Icon
                name="fal fa-envelope v-middle-adjust"
                size="16"
                colorFont="rgba(255, 255, 255, .5)"
              />
              {{ person.email }}
            </div>
            <div>
              <template v-if="person.address.city && person.address.state">
                <Icon
                  name="far fa-map-marker-alt v-middle-adjust"
                  size="16"
                  colorFont="rgba(255, 255, 255, .5)"
                />
                {{ person.address.city }}, {{ person.address.state }}
              </template>
              <template v-else></template>
            </div>
          </div>
        </div>
      </v-layout>
      <v-layout row pa-4 wrap-dash>
        <v-flex md9 lg12 bg-white m-768-r-0>
          <div class="my-donations-header perfil display-flex">
            <div>
              <Icon
                name="fal fa-user-edit"
                size="30"
                class="mr-3"
                colorFont="#C3C6D5"
              />
              <h2 class="mb-0">Meus dados</h2>
            </div>
            <div>
              <button
                class="btn-ghost-primary v-btn v-btn--flat white--text v-btn-round mr-0"
                type="button"
                @click="edit()"
                v-if="enableEdit"
              >
                <Icon name="fal fa-pencil" size="16" class="mr-2" />Editar
              </button>
              <button
                class="btn-red flex-inherit ml-auto v-btn v-btn--flat v-btn--round theme--light white--text"
                @click="edit()"
                v-if="!enableEdit"
              >
                <Icon name="fal fa-times" size="16" class="mr-2" />Cancelar
              </button>
              <button
                class="btn-primary flex-inherit ml-auto v-btn v-btn--flat v-btn--round theme--light white--text mr-0"
                @click="save"
                v-if="enableSave"
              >
                <Icon name="fal fa-check" size="16" class="mr-2" />Salvar
              </button>
            </div>
          </div>
          <fieldset action :disabled="false" class="b-none pa-4 mw-total">
            <v-layout row wrap>
              <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputText
                  ref="nameInput"
                  type="text"
                  :textLabel="isCompany ? 'Nome do responável' : 'Nome'"
                  v-model="person.name"
                  :disabled="disabledInputs"
                  labelCharLimit="50"
                  invalidInputMessage="Preencha o nome"
                  :required="true"
                />
              </v-flex>
              <v-flex v-if="isCompany" xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputText
                  ref="socialReasonInput"
                  type="text"
                  textLabel="Razão Social"
                  v-model="person.socialReason"
                  :disabled="disabledInputs"
                  labelCharLimit="255"
                  invalidInputMessage="Preencha a razão social"
                  :required="true"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex v-if="isCompany" xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputText
                  ref="cnpjInput"
                  type="tel"
                  textLabel="CNPJ"
                  v-model="person.cnpj"
                  mask="##.###.###/####-##"
                  :disabled="disabledInputs"
                  :required="true"
                  invalidInputMessage="Preencha o CNPJ"
                />
              </v-flex>
              <v-flex v-else xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputText
                  ref="cpfInput"
                  type="tel"
                  textLabel="CPF"
                  v-model="person.cpf"
                  mask="###.###.###-##"
                  :disabled="true"
                  :required="campaign.cpfIsRequired"
                  invalidInputMessage="Preencha o CPF"
                />
              </v-flex>
              <v-flex xs12 sm12 md6 lg5>
                <InputText
                  ref="phoneInput"
                  type="tel"
                  textLabel="Telefone Celular"
                  v-model="person.phone"
                  mask="(##)#####-####"
                  :disabled="disabledInputs"
                  :validation="validateCellPhone"
                  invalidInputMessage="Preencha o celular"
                  :required="true"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputText
                  ref="emailInput"
                  type="text"
                  textLabel="E-mail"
                  v-model="person.email"
                  :disabled="disabledInputs"
                  :validation="validateEmail"
                  invalidInputMessage="Preencha o email"
                  :required="true"
                />
              </v-flex>
              <v-flex xs12 sm12 md6 lg5>
                <InputText
                  ref="alternativeEmailInput"
                  type="text"
                  textLabel="E-mail Alternativo"
                  v-model="person.alternativeEmail"
                  :disabled="disabledInputs"
                  :validation="validateAlternativeEmail"
                  invalidInputMessage="Preencha o email alternativo"
                  :required="true"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputSelect
                  ref="genderInput"
                  textLabel="Gênero"
                  valueAttribute="id"
                  textAttribute="name"
                  :items="genderItems"
                  v-model="person.gender"
                  :disabled="disabledInputs"
                  invalidInputMessage="Selecione um gênero"
                  :useAnotherDefaultValue="true"
                  :required="true"
                ></InputSelect>
              </v-flex>
              <v-flex xs12 sm12 md6 lg5>
                <InputDate
                  ref="birthDateInput"
                  place-holder="__/__/____"
                  textLabel="Data de Nascimento"                  
                  :allowSingleDate="true"
                  v-model="person.birthDate"
                  :disabled="disabledInputs"
                  :validation="validateBirthdate"
                  :required="true"
                  invalidInputMessage="É permitido somente maiores de 16 anos"
                ></InputDate>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex sm10>
                <BorderSeparation />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputText
                  ref="companyInput"
                  type="text"
                  textLabel="Nome da empresa"
                  v-model="person.companyName"
                  :disabled="disabledInputs"
                  :required="campaign.informCompany"
                  invalidInputMessage="Preencha o nome da Empresa"
                />
              </v-flex>
              <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputText
                  ref="departamentInput"
                  type="text"
                  textLabel="Nome do departamento"
                  v-model="person.departamentName"
                  :disabled="disabledInputs"
                  :required="campaign.informDepartament"
                  invalidInputMessage="Preencha o nome da Empresa"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex sm10>
                <BorderSeparation />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputText
                  ref="cepInput"
                  type="tel"
                  textLabel="CEP Residencial"
                  v-model="person.address.postalCode"
                  mask="#####-###"
                  :disabled="disabledInputs"
                  :required="true"
                  @input="searchCEP()"
                  invalidInputMessage="Preencha o CEP"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputText
                  ref="streetInput"
                  type="text"
                  textLabel="Logradouro"
                  v-model="person.address.street"
                  :disabled="disabledInputs"
                  :required="true"
                  invalidInputMessage="Preencha o Logradouro"
                />
              </v-flex>
              <v-flex xs12 sm12 md6 lg5>
                <InputText
                  ref="numberInput"
                  type="text"
                  textLabel="Número"
                  v-model="person.address.number"
                  :disabled="disabledInputs"
                  :required="true"
                  invalidInputMessage="Preencha o número"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                <InputText
                  type="text"
                  textLabel="Complemento"
                  v-model="person.address.complement"
                  :disabled="disabledInputs"
                />
              </v-flex>
              <v-flex xs12 sm12 md6 lg5>
                <InputText
                  ref="neighborhoodInput"
                  type="text"
                  textLabel="Bairro"
                  v-model="person.address.neighborhood"
                  :disabled="disabledInputs"
                  :required="true"
                  invalidInputMessage="Preencha o bairro"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md8 lg9 xl10 pr-3 p-950-r-0>
                <InputText
                  ref="cityInput"
                  type="text"
                  textLabel="Cidade"
                  v-model="person.address.city"
                  :disabled="true"
                  :required="true"
                  invalidInputMessage="Preencha a cidade"
                />
              </v-flex>
              <v-flex xs12 sm12 md6 lg5>
                <InputSelect
                  ref="stateInput"
                  textLabel="Estado"
                  :items="stateItems"
                  v-model="person.address.state"
                  :disabled="true"
                  :required="true"
                  invalidInputMessage="Selecione um estado"
                ></InputSelect>
              </v-flex>
            </v-layout>
          </fieldset>
        </v-flex>
        <ModalResetPassword
          v-model="dialog"
          modalTitle="Alterar senha"
          :cardTitle="true"
          :withClose="true"
          :modalFooter="true"
          myMaxWidth="640px"
        >
          <v-container fluid pa-0>
            <v-layout pa-4 wrap row>
              <InputPassword
                v-model="password"
                type="password"
                textLabel="Digite a nova senha"
                :force="true"
                @levelPassword="changeLevelPassword"
                @input="disabledButton"
                insideInput="Moderada"
                helpLabel="Sua senha deve conter ao menos 8 caracteres com letras e números."
              />
              <InputPassword
                v-model="passwordConfirm"
                textLabel="Confirme sua nova senha"
                type="password"
                insideInput="Moderada"
                :force="false"
                @input="disabledButton"
              />
            </v-layout>
          </v-container>
          <template v-slot:footer>
            <v-container fluid>
              <v-layout>
                <v-flex text-xs-center>
                  <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary px-5 text-uppercase"
                    large
                    :disabled="disableButtonAlter"
                    >Alterar Senha</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-container>
          </template>
        </ModalResetPassword>
      </v-layout>
    </div>
  </div>
</template>

<script type="plain/text">
import ProjectPercent from "@/components/ProjectPercent.vue";
import Nav from "@/components/Nav.vue";
import Icon from "@/components/Icon.vue";
import Person from "@/scripts/models/person.model";
import CreditCard from "@/scripts/models/creditCard.model";
import Campaign from "@/scripts/models/campaign.model";
import ModalResetPassword from "@/components/Modal.vue";
import InputPassword from "@/components/input/InputPassword.vue";
import Address from "@/scripts/models/address.model";
import TokenService from "@/scripts/services/token.service";
import CartService from "@/scripts/services/cart.service";
import PermissionStorage from "@/scripts/services/permissionStorage.service";
import UserService from "@/scripts/services/user.service";
import PersonService from "@/scripts/services/person.service";
import CampaignService from "@/scripts/services/campaign.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { parse } from "date-fns";
import LocationService from "@/scripts/services/location.service.js";

export default {
  extends: ValidatableComponent,
  components: {
    ProjectPercent,
    Nav,
    InputPassword,
    ModalResetPassword,
    Icon,
  },
  data: () => {
    return {
      formatterHelper: new FormatterHelper(),
      personService: new PersonService(),
      userService: new UserService(),
      cartService: new CartService(),
      campaignService: new CampaignService(),
      person: new Person(),
      locationService: new LocationService(),
      disableButtonAlter: true,
      levelPassword: 0,
      password: "",
      passwordConfirm: "",
      disabledInputs: true,
      enableEdit: true,
      enableSave: false,
      cepValidation: false,
      dialog: false,
      isCompany: false,
      emailInitial: null,
      campaign: new Campaign(),
      genderItems: [
        {
          id: "M",
          name: "Masculino",
        },
        {
          id: "F",
          name: "Feminino",
        },
        {
          id: "O",
          name: "Outros",
        },
        {
          id: "-",
          name: "Não informar",
        },
      ],
      stateItems: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
      ],
    };
  },
  created() {
    this.getProfile();
    this.validationMethod = this.validate;
    this.campaignService.findBySubdomain().then(
      function (data) {
        if (data != null) this.campaign = data;
      }.bind(this)
    );
  },
  methods: {
    changeLevelPassword(levelPassword) {
      this.levelPassword = levelPassword;
    },
    disabledButton() {
      if (this.password.length >= 8 && this.levelPassword >= 2)
        if (this.password === this.passwordConfirm)
          this.disableButtonAlter = false;
        else this.disableButtonAlter = true;
      else this.disableButtonAlter = true;
    },
    goToProfile() {
      this.router.push({
        name: Routes.web.Profile,
      });
    },
    getUrl() {
      let url =
        "https://ui-avatars.com/api/?background=F1F2F5&color=80849A&size=150&name=";
      if (this.person != null) url += this.person.name.split().join("+");
      return url;
    },
    searchCEP() {
      if (
        this.person.address.postalCode == "" ||
        this.person.address.postalCode.length < 8
      )
        return false;
      this.$store.commit("SET_LOADER", true);
      this.locationService
        .searchCEP(this.person.address.postalCode)
        .then(data => {
          this.searchCEPCallback(data)
        }).catch((err) => {
          CommonHelper.swal(
            "Não foi possível encontrar este CEP!"
          );
          this.$store.commit("SET_LOADER", false);
        });
    },
    searchCEPCallback(obj) {
      if (!obj.erro) { 
        this.person.address.street = obj.street ? obj.street : this.person.address.street;
        this.person.address.neighborhood = obj.neighborhood ? obj.neighborhood : this.person.address.neighborhood;
        this.person.address.city = obj.city;
        this.person.address.state = obj.state;
        this.cepValidation = true;
      } else {
        this.person.address.street = "";
        this.person.address.neighborhood = "";
        this.person.address.city = "";
        this.person.address.state = "";
        this.cepValidation = false;
      }
      this.$store.commit("SET_LOADER", false);
    },
    getProfile() {
      this.personService.getProfile(this.getProfileCallback);
    },
    getProfileCallback(data) {
      this.person = data;
      if(this.person.cnpj)
        this.isCompany = true
      this.emailInitial = this.person.email;
      if (this.person.address == null) {
        this.person.address = new Address();
      }
    },
    sendRecoverPasswordEmail() {
      this.userService.sendRecoverPasswordEmail(this.person.email);
      // CommonHelper.swal(
      //   "Um e-mail de redefinição de senha foi enviado para o endereço de e-mail cadastrado no seu perfil."
      // );
    },
    changePassword() {
      this.sendRecoverPasswordEmail();
      this.logout();
    },
    logout() {
      TokenService.removeToken();
      //TokenService.removeProfileLogged();
      PermissionStorage.removeRoles();
    },
    save() {
      if (this.isValid()) {
        if (this.emailInitial != this.person.email)
          this.$store.commit(
            "SET_SNACKBAR_SUCCESS_MESSAGE",
            "Uma confirmação será enviada para o seu novo e-mail cadastrado. Somente após a ativação o seu login será atualizado."
          );
        this.personService.saveProfile(this.saveCallback, this.person);
      }
    },
    saveCallback(data) {
      if (this.emailInitial != this.person.email) {
        TokenService.removeToken();
        this.$store.commit("REMOVE_ROLES");
        this.$router.push({
          name: Routes.web.Login,
        });
      }

      this.person = data;
      this.edit();
    },
    edit() {
      this.disabledInputs = !this.disabledInputs;
      this.enableEdit = !this.enableEdit;
      this.enableSave = !this.enableSave;
    },
    validateEmail() {
      return CommonHelper.validateEmail(this.person.email);
    },
    validateAlternativeEmail() {
      if (this.person.alternativeEmail)
        return CommonHelper.validateEmail(this.person.alternativeEmail);
      else return true;
    },
    validateCep() {
      return CommonHelper.validateCep(this.person.address.postalCode);
    },
    validateBirthdate() {
      var birthDate = parse(this.person.birthDate);
      var currentAge = CommonHelper.getAge(birthDate);

      if (currentAge < 16) {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "Você precisa ter mais de 16 anos");
      }
      return currentAge >= 16;
    },
    validateCellPhone() {
      return CommonHelper.validateCellPhone(this.person.phone);
    },
    validate(performMethods) {
      if (!this.cepValidation) {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "CEP Inválido");
        return false;
      }
      return this.validateComponents(performMethods);
    },
  },
};
</script>

<style>
</style>
