<template>
  <div class="box-project-percent">
    <div>
      <FileImage :file="getFile()" />
      <!-- <img src="https://images.pexels.com/photos/1234099/pexels-photo-1234099.jpeg" alt /> -->
    </div>
    <div class="content-box-percent pl-4 w-100">
      <div class="display-flex justify-space-between align-center">
        <div></div>
        <span title="Percentual arrecadado em relação à expectativa de captação do projeto">{{calculatePercentual(project.totalDonatedValueWithCapturedValue, project.totalValue)}}</span>
      </div>
      <div class="content-box">
        <h5>{{project.institution.entity.name}}</h5>
        <h3>{{project.shortName}}</h3>
        <div class="display-flex justify-space-between align-center">
          <button
            class="btn-primary v-btn--flat ma-0 v-btn--smallv-btn--round white--text v-btn"
            @click="goToProjectLP(project.id)"
          >Comunidade</button>
          <!-- <button class="btn-remove">Remover</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script type="plain/text">
import { Routes } from "@/scripts/models/enums/routes.enum";
import CommonHelper from "@/scripts/helpers/common.helper";
import FileImage from "@/components/FileImage.vue";
import FileService from "@/scripts/services/file.service";
export default {
  props: ["project"],
  components: {
    FileImage
  },
  data() {
    return {
      file: null,
      fileService: new FileService(),
      loaded: false,
      routes: null
    };
  },
  created() {
    this.routes = Routes;
  },
  methods: {
    getFile() {
      if (this.file == null && !this.loaded) {
        this.loaded = true;
        this.fileService
          .getById(this.getFileId())
          .then(data => (this.file = data));
      }
      return this.file;
    },
    getFileId() {
      if (this.project != null && this.project.projectLandingPageConfig != null)
        return this.project.projectLandingPageConfig.heroFileId;
    },
    calculatePercentual(current, total) {
      let v = 100;
      if (total > 0) {
        v = Math.round((current / total) * 100);
      }
      return v + "%";
    },
    goToProjectLP(projectId){
      this.$router.push({
        name: Routes.web.ProjectLP,
        query: { projectId: projectId }
      });
    }
  }
};
</script>

<style>
</style>
